<template>
  <div>
    <div class="air__utils__heading"> <h5>Master de Análisis</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <div class="row mb-5">
      <div class="col">
        <a-tabs default-active-key="1" type="card" size="large">
          <!-- <a-tab-pane key="1">
              <span slot="tab">
                <a-icon type="usergroup-add"/>
                Configuración de Grupos
              </span>
              <div class="card">
                <div class="card-body text-center">
                  <group-table :table-object="groupTableObj"/>
                </div>
              </div>
          </a-tab-pane> -->
          <a-tab-pane key="1">
            <span slot="tab">
              <a-icon type="file-ppt"/>
              Pruebas de Análisis
            </span>
            <div class="card">
              <div class="card-body text-center">
                <test-table :table-object="testTableObj"/>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
              <span slot="tab">
                <a-icon type="experiment"/>
                Elementos de Análisis
              </span>
              <div class="card">
                <div class="card-body text-center">
                  <analysis-elements-table :table-object="analysisElementsTableObj"/>
                </div>
              </div>
          </a-tab-pane>
          <!-- <a-tab-pane key="4">
              <span slot="tab">
                <a-icon type="file-search"/>
                Detalle de las Pruebas
              </span>
              <div class="card">
                <div class="card-body text-center">
                  <reagent-transaction-table :table-object="detailsTableObj"/>
                </div>
              </div>
          </a-tab-pane> -->
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import AnalysisElementsTable from '@/views/geocycle/laboratories/analysis_master/components/analysisElementsTable'
// import GroupTable from '@/views/geocycle/laboratories/analysis_master/components/groupTable'
// import ReagentTransactionTable from '@/views/geocycle/laboratories/reagent_warehouse/components/reagentTransactionTable'
import TestTable from '@/views/geocycle/laboratories/analysis_master/components/testTable'
export default {
  name: 'testMaster',
  components: {
    AnalysisElementsTable,
    TestTable,
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'laboratories',
          breadcrumbName: 'Laboratorio',
        },
        {
          breadcrumbName: 'Master de Análisis',
          aIcon: '',
        },
      ],
      groupTableObj: {
        resourceType: '',
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
            class: 'small text-center',
          },
          {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            class: 'small text-center',
          },
          {
            title: 'Centro de Trabajo',
            dataIndex: 'workCenters',
            key: 'workCenters',
            class: 'small text-center',
            scopedSlots: { customRender: 'workCenters' },
          },
          {
            title: 'Corrientes',
            dataIndex: 'currents',
            key: 'currents',
            class: 'small text-center',
            scopedSlots: { customRender: 'currents' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      analysisElementsTableObj: {
        resourceType: '',
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
            class: 'small text-center',
          },
          {
            title: 'Centro de Trabajo',
            dataIndex: 'work_centers',
            key: 'workCenters',
            class: 'small text-center',
            scopedSlots: { customRender: 'workCenters' },
          },
          {
            title: 'Nombre de Elementos de Análisis',
            dataIndex: 'name',
            key: 'name',
            class: 'small text-center',
          },
          {
            title: 'Corrientes',
            dataIndex: '',
            key: 'currents',
            class: 'small text-center',
            scopedSlots: { customRender: 'currents' },
          },
          {
            title: 'Limites',
            dataIndex: '',
            key: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'limits' },
          },
          // {
          //   title: 'Límite Inferior',
          //   dataIndex: 'down_limit',
          //   key: 'down_limit',
          //   class: 'small text-center',
          // },
          // {
          //   title: 'Límite Superior',
          //   dataIndex: 'up_limit',
          //   key: 'up_limit',
          //   class: 'small text-center',
          // },
          {
            title: 'Unidad de medida',
            dataIndex: 'ums',
            key: 'ums',
            class: 'small text-center',
            scopedSlots: { customRender: 'ums' },
          },
          {
            title: 'Instrumentos',
            dataIndex: 'instruments',
            key: 'instruments',
            class: 'small text-center',
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      testTableObj: {
        resourceType: '',
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
            class: 'small text-center',
          },
          {
            title: 'Centro de Trabajo',
            dataIndex: 'work_centers',
            key: 'workCenters',
            class: 'small text-center',
            width: '9%',
            scopedSlots: { customRender: 'workCenters' },
          },
          {
            title: 'Prueba de Análisis',
            dataIndex: 'name',
            key: 'name',
            class: 'small text-center',
          },
          {
            title: 'Costo',
            dataIndex: '',
            key: 'cost',
            class: 'small text-center',
            scopedSlots: { customRender: 'testCost' },
          },
          {
            title: 'Acreditación',
            dataIndex: 'accreditation',
            key: 'accreditation',
            class: 'small text-center',
          },
          {
            title: 'Notas',
            dataIndex: 'notes',
            key: 'notes',
            class: 'small text-center',
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      detailsTableObj: {
        resourceType: 'reagentTransactions',
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
            class: 'small text-center',
          },
          {
            title: 'Centro de Trabajo',
            dataIndex: 'workCenters',
            key: 'workCenters',
            class: 'small text-center',
            scopedSlots: { customRender: 'workCenters' },
          },
          {
            title: 'Nombre',
            dataIndex: 'users',
            key: 'users',
            class: 'small text-center',
            scopedSlots: { customRender: 'users' },
          },
          {
            title: 'Tipo de Transacción',
            dataIndex: 'warehouseTransactions',
            key: 'warehouseTransactions',
            class: 'small text-center',
            scopedSlots: { customRender: 'warehouseTransactions' },
          },
          {
            title: 'Proveedor',
            dataIndex: '',
            key: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'reagentProviders' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
    }
  },
}
</script>
