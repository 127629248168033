<template>
  <div>
    <!--BOTONERA  -->
    <div class="row justify-content-end mr-2">
      <b-button-group>
        <b-button variant="primary" @click="OpenModal()" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/> Nuevo
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/>
          </div>
        </b-button>
      </b-button-group>
    </div>

    <searchingBar :components="['filter', 'sort', 'pages']"
                  :sortByOptions="sortByOptions"
                  @Fectch="fetchTable"
                  @filterValue="filterValue = $event"
                  @sortValue="tableObject.sortValue = $event"
                  @pageSize="tableObject.pagination.pageSize = $event"
                  @currentPageValue="grouptableObject.currentPage = $event"
    />

    <div class="row" style="margin-left:1px">
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="plant-group" label="Planta" label-for="plant">
          <a-select v-model="filterPlant" allowClear @change="fetchTable" style="width: 100%;margin-bottom:13px"
                  placeholder="Seleccionar Planta" :filter-option="filterOption" show-search>
            <a-select-option  v-for="option in work_centers" :key="option.id" :value="option.id" >
              {{option.name}}
            </a-select-option>
          </a-select>
        </b-form-group>
      </div>
    </div>

    <!-- MODAL -->
    <b-modal title="Detalle de Elementos de Análisis" ref="modal-Form" size="lg">
      <my-spinner :load="spinnerLoad"/>
        <b-container fluid v-show="!spinnerLoad">
          <a-form-model :model="resourceObj.form" :rules="formRules" ref="rule-Form"
              layout="vertical" :wrapper-col="{ span: 24}">

            <a-form-model-item label="Código" ref="code"  prop="code" >
              <a-input v-model.trim="resourceObj.form.code" placeholder="Código de Elementos de Análisis" size="large"/>
            </a-form-model-item>

            <a-form-model-item label="Nombre" ref="name"  prop="name" >
              <a-input v-model.trim="resourceObj.form.name" placeholder="Nombre de Elementos de Análisis" size="large"/>
            </a-form-model-item>

            <a-form-model-item label="Centro de Trabajo" ref="work_centers" prop="work_centers" >
              <a-select v-model="resourceObj.form.work_center_id"
                          placeholder="Centro de Trabajo"
                          show-search :filter-option="filterOption">
                  <a-select-option  v-for="option in work_centers" :key="option.id" :value="option.id" >
                    {{option.name}}
                  </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="Corrientes" ref="currents" prop="currents" >
              <a-select v-model="resourceObj.form.currents"
                          placeholder="Corrientes" mode="multiple"
                          show-search :filter-option="filterOption">
                  <a-select-option  v-for="option in currents" :key="option.id" :value="option.id" >
                    {{option.name}}
                  </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="Limite Inferior" ref="down_limit"  prop="down_limit" >
              <a-input v-model.trim="resourceObj.form.down_limit" placeholder="Limite Inferior" size="large"/>
            </a-form-model-item>
            <a-form-model-item label="Limite Superior" ref="up_limit"  prop="up_limit" >
              <a-input v-model.trim="resourceObj.form.up_limit" placeholder="Limite Superior" size="large"/>
            </a-form-model-item>

            <a-form-model-item label="Referencia" ref="reference"  prop="reference" >
              <a-input v-model.trim="resourceObj.form.reference" placeholder="Ingrese Referencia" size="large"/>
            </a-form-model-item>

            <a-form-model-item label="Unidad de Medida" ref="u_m_s" prop="u_m_s" >
              <a-select v-model="resourceObj.form.u_m_s_id"
                          placeholder="Seleccione la Unidad de Medida"
                          show-search :filter-option="filterOption">
                  <a-select-option  v-for="option in u_m_s" :key="option.id" :value="option.id" >
                    {{option.name}}
                  </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="Instrumentos" ref="instruments"  prop="instruments" >
              <a-input v-model.trim="resourceObj.form.instruments" placeholder="Ingrese Instrumentos" size="large"/>
            </a-form-model-item>

            <div class="row justify-content-end">
              <b-button-group>
                <b-button  variant="primary" @click="Submit" pill>
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/> Guardar
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/>
                  </div>
                </b-button>
                <b-button v-if="resourceObj.form.id"  variant="danger" @click="Delete" pill>
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                      <b-icon icon="trash"/> Eliminar
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                      <b-icon icon="trash"/>
                  </div>
                </b-button>
              </b-button-group>
            </div>
          </a-form-model>

        </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->

    <a-table :columns="tableObject.columns"
             :data-source="tableObject.data"
             :pagination="tableObject.pagination"
             :loading="tableObject.loading"
             @change="fetchTable"
             :row-key="record => record.id"
    >
      <template slot="workCenters" slot-scope="record">
        {{ record.code }} - {{ record.name }}
      </template>

      <template slot="ums" slot-scope="record">
        {{ record.code }}
      </template>

      <template slot="currents" slot-scope="record">
        <li v-for="item in record.current_relations" :key="item.id">
          {{ item.name }}
        </li>
      </template>

      <template slot="limits" slot-scope="record">
        <div>{{'Limite Superior: ' + record.up_limit}}</div>
        <div>{{'Limite Inferior: ' + record.down_limit}}</div>
        <div>{{'Referencia: ' + record.reference}}</div>
      </template>

      <template slot="action" slot-scope="data">
        <b-button  class="mr-1 mb-1" variant="secondary" @click="OpenModal(data)" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/> Editar
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/>
          </div>
        </b-button>
      </template>
    </a-table>

  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'

export default {
  name: 'analysisElementsTable',
  props: {
    tableObject: {
      type: [Object],
      required: true,
    },
  },
  data() {
    return {
      sortByOptions: [],
      spinnerLoad: false,
      resourceObj: {
        resourceType: 'labAnalysisElements',
        form: {
          id: '',
          code: '',
          name: '',
          down_limit: '',
          up_limit: '',
          reference: '',
          instruments: '',
          currents: [],
          work_center_id: undefined,
          u_m_s_id: undefined,
        },
        // relationships: ['workCenters', 'analysisGroups', 'ums'],
      },
      work_centers: [],
      currents: [],
      u_m_s: [],
      formRules: {
        name: [{ required: true, message: 'El Nombre es obligatorio.', trigger: 'blur' }],
        code: [{ required: true, message: 'El Código es obligatorio.', trigger: 'blur' }],
        down_limit: [{ required: true, message: 'El Limite Inferior es obligatorio.', trigger: 'blur' }],
        currents: [{ required: true, message: 'Las corrientes son obligatorias.', trigger: 'blur' }],
        reference: [{ required: true, message: 'La referencia es obligatorio.', trigger: 'blur' }],
        instruments: [{ required: true, message: 'Los instrumentos son obligatorios.', trigger: 'blur' }],
        up_limit: [{ required: true, message: 'El Limite Superior es obligatorio.', trigger: 'blur' }],
        u_m_s_id: [{ required: true, message: 'La UM es obligatoria.', trigger: 'blur' }],
        work_center_id: [{ required: true, message: 'El Centro de Trabajo es obligatorio.', trigger: 'blur' }],
      },
      // Combos de formulario
      typesCombo: [{ value: 'una', label: 'UNA' }, { value: 'Dos', label: 'DOS' },
        { value: 'Tres', label: 'TRES' }, { value: 'Cuatro', label: 'CUATRO' },
        { value: 'Cinco ', label: 'CINCO' }, { value: 'Seis ', label: 'SEIS' },
        { value: 'Siete ', label: 'SIETE' }, { value: 'Ocho ', label: 'OCHO' },
      ],
    }
  },
  mixins: [fractalMixin],
  methods: {
    async fetchTable(pagEvent = {}) {
      // API v2
      const params = {
        page: (Object.keys(pagEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.tableObject.currentPage}`,
        pageSize: `${this.tableObject.pagination.pageSize}`,
        orderField: 'code',
        orderType: 'asc',
      }
      if (this.filterPlant !== '') params.workCenterId = this.filterPlant
      // if (this.filterFamily !== '') params.familyId = this.filterFamily
      if (this.filterValue !== '') params.search = this.filterValue
      await this.GetTableResourcev2(pagEvent, this.resourceObj.resourceType, this.tableObject, params)
    },
    async fetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/workCenters',
          params: params1,
        },
        {
          path: '/fetch/currents?pageSize=1000',
          params: params1,
        },
        {
          path: '/fetch/ums?pageSize=50',
          params: params1,
        },
      ]
      const [plants, currents, ums] = await this.GetConcurrencyResources(arr)
      this.work_centers = plants
      this.currents = currents
      this.u_m_s = ums
    },
    async getWork_centers() {
      this.work_centers = await this.GetResource('workCenters', {
        sortBy: 'name',
        'fields[workCenters]': 'name',
      })
    },
    async getU_m_s() {
      this.u_m_s = await this.GetResource('ums', {
        sortBy: 'name',
        'fields[ums]': 'name',
      })
    },
    OpenModal(data = {}) {
      this.$refs['modal-Form'].show()
      this.spinnerLoad = true
      if (Object.keys(data).length === 0) {
        //  Store case
        this.resourceObj.form = {
          id: '',
          work_center_id: undefined,
          code: '',
          name: '',
          down_limit: '',
          up_limit: '',
          instruments: '',
          reference: '',
          currents: [],
          u_m_s_id: undefined,
        }
      } else {
        // Update case
        console.log('Objeto: ', data)
        this.resourceObj.form = {
          id: data.id,
          work_center_id: parseInt(data.work_center_id),
          u_m_s_id: parseInt(data.u_m_s_id),
          code: data.code,
          name: data.name,
          currents: data.currents,
          reference: data.reference,
          down_limit: data.down_limit,
          instruments: data.instruments,
          up_limit: data.up_limit,
        }
      }
      this.spinnerLoad = false
    },
    CloseModal() {
      this.$refs['modal-Form'].hide()
      this.resourceObj.form = {
        id: '',
        work_center_id: undefined,
        code: '',
        name: '',
        down_limit: '',
        up_limit: '',
        u_m_s_id: undefined,
        instruments: '',
        reference: '',
        currents: [],
      }
    },
    async Submit() {
      this.$refs['rule-Form'].validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            delete this.resourceObj.form.id
            console.log('POST')
            await this.PostResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else {
            console.log('UPDATE')
            await this.PutResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          }
        } else return false
      })
    },
    async Delete() {
      await this.DeleteResourcev2(this.resourceObj.resourceType, this.resourceObj.form.id, this.SuccessCallback)
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.fetchTable()
      this.CloseModal()
    },
  },
  async mounted() {
    await this.fetchTable()
    await this.fetchCombos()
  },
}
</script>
