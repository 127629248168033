<template>
  <div>
    <!--BOTONERA  -->
    <div class="row justify-content-end mr-2">
      <b-button-group>
        <b-button  variant="primary" @click="OpenModal()" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/> Nuevo
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/>
          </div>
        </b-button>
      </b-button-group>
    </div>
    <!--BOTONERA  -->

    <searchingBar :components="['filter', 'sort', 'pages']"
                  :sortByOptions="sortByOptions"
                  @Fectch="fetchTable"
                  @filterValue="filterValue = $event"
                  @sortValue="tableObject.sortValue = $event"
                  @pageSize="tableObject.pagination.pageSize = $event"
                  @currentPageValue="grouptableObject.currentPage = $event"
    />

    <div class="row" style="margin-left:1px">
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="plant-group" label="Planta" label-for="plant">
          <a-select v-model="filterPlant" allowClear @change="fetchTable" style="width: 100%;margin-bottom:13px"
                  placeholder="Seleccionar Planta" :filter-option="filterOption" show-search>
            <a-select-option  v-for="option in work_centers" :key="option.id" :value="option.id" >
              {{option.name}}
            </a-select-option>
          </a-select>
        </b-form-group>
      </div>
    </div>

    <!-- MODAL -->
    <b-modal title="Detalle de Prueba de Análisis" ref="modal-Form" size="lg">
      <my-spinner :load="spinnerLoad"/>
        <b-container fluid v-show="!spinnerLoad">
          <a-form-model :model="resourceObj.form" :rules="formRules" ref="rule-Form"
              layout="vertical" :wrapper-col="{ span: 24}">

            <a-form-model-item label="Código" ref="code"  prop="code" >
              <a-input v-model.trim="resourceObj.form.code" placeholder="Código de Configuración de Grupos" size="large"/>
            </a-form-model-item>

            <a-form-model-item label="Centro de Trabajo" ref="work_centers" prop="work_centers" >
              <a-select v-model="resourceObj.form.work_center_id"
                          placeholder="Centro de Trabajo"
                          show-search :filter-option="filterOption">
                  <a-select-option  v-for="option in work_centers" :key="option.id" :value="option.id" >
                    {{option.name}}
                  </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="Prueba de Análisis" ref="name"  prop="name" >
              <a-input v-model.trim="resourceObj.form.name" placeholder="Nombre de Prueba" size="large"/>
            </a-form-model-item>

            <a-form-model-item label="Costo" ref="cost"  prop="cost" >
              <a-input-number v-model.trim="resourceObj.form.cost" :min="0" :precision="2" :max="1000000" :step="1" size="large" style="width: 100%"/>
            </a-form-model-item>

            <a-form-model-item label="Acreditación" ref="accreditation"  prop="accreditation" >
              <a-textarea v-model.trim="resourceObj.form.accreditation" placeholder="Acreditación" size="large"/>
            </a-form-model-item>

            <a-form-model-item label="Notas" ref="notes"  prop="notes" >
              <a-textarea v-model.trim="resourceObj.form.notes" placeholder="Notas" :auto-size="{ minRows: 4 }"/>
            </a-form-model-item>

            <div class="row justify-content-end">
              <b-button-group>
                <b-button  variant="primary" @click="Submit" pill>
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/> Guardar
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/>
                  </div>
                </b-button>
                <b-button v-if="resourceObj.form.id"  variant="danger" @click="Delete" pill>
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                      <b-icon icon="trash"/> Eliminar
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                      <b-icon icon="trash"/>
                  </div>
                </b-button>
              </b-button-group>
            </div>
          </a-form-model>

        </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->

    <a-table :columns="tableObject.columns"
             :data-source="tableObject.data"
             :pagination="tableObject.pagination"
             :loading="tableObject.loading"
             @change="fetchTable"
             :row-key="record => record.id"
    >
      <template slot="workCenters" slot-scope="record">
        {{ record.code }} - {{ record.name }}
      </template>

      <template slot="testCost" slot-scope="record">
        {{ parseFloat(record.cost).toFixed(2) }}
      </template>

      <template slot="action" slot-scope="data">
        <b-button  class="mr-1 mb-1" variant="secondary" @click="OpenModal(data)" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/> Editar
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/>
          </div>
        </b-button>
      </template>

    </a-table>

  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'

export default {
  name: 'testTable',
  props: {
    tableObject: {
      type: [Object],
      required: true,
    },
  },
  data() {
    return {
      // Filter variables
      filterValue: '',
      sortByOptions: [],
      spinnerLoad: false,
      resourceObj: {
        resourceType: 'labAnalysis',
        form: {
          id: '',
          code: '',
          name: '',
          cost: '',
          accreditation: '',
          notes: '',
          work_center_id: undefined,
        },
      },
      work_centers: [],
      formRules: {
        name: [{ required: true, message: 'El Nombre es obligatorio.', trigger: 'blur' }],
        code: [{ required: true, message: 'El Código es obligatorio.', trigger: 'blur' }],
        cost: [{ required: true, message: 'El Costo es obligatorio.', trigger: 'blur' }],
        accreditation: [{ required: true, message: 'La Acreditación es obligatoria.', trigger: 'blur' }],
        work_center_id: [{ required: true, message: 'El Centro de Trabajo es obligatorio.', trigger: 'blur' }],
      },
    }
  },
  mixins: [fractalMixin],
  methods: {
    async fetchTable(pagEvent = {}) {
      // API v2
      const params = {
        page: (Object.keys(pagEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.tableObject.currentPage}`,
        pageSize: `${this.tableObject.pagination.pageSize}`,
        orderField: 'code',
        orderType: 'asc',
      }
      if (this.filterPlant !== '') params.workCenterId = this.filterPlant
      // if (this.filterFamily !== '') params.familyId = this.filterFamily
      if (this.filterValue !== '') params.search = this.filterValue
      await this.GetTableResourcev2(pagEvent, this.resourceObj.resourceType, this.tableObject, params)
    },
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    async getWork_centers() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/workCenters',
          params: params1,
        },
        {
          path: '/fetch/families?pageSize=1000',
          params: params1,
        },
      ]
      const [plants, families] = await this.GetConcurrencyResources(arr)
      this.work_centers = plants
      this.familiesCombo = families
    },
    OpenModal(data = {}) {
      this.$refs['modal-Form'].show()
      // this.getWork_centers()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.resourceObj.form = {
          id: '',
          work_center_id: undefined,
          code: '',
          name: '',
          cost: '',
          accreditation: '',
          notes: '',
        }
      } else {
        // Update case
        console.log('Data: ', data)
        this.resourceObj.form = {
          id: data.id.toString(),
          work_center_id: parseInt(data.work_center_id),
          code: data.code,
          name: data.name,
          cost: data.cost,
          accreditation: data.accreditation,
          notes: data.notes,
        }
      }
    },
    CloseModal() {
      this.$refs['modal-Form'].hide()
      this.resourceObj.form = {
        id: '',
        work_center_id: undefined,
        code: '',
        name: '',
        cost: '',
        accreditation: '',
        notes: '',
      }
    },
    async Submit() {
      this.$refs['rule-Form'].validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            delete this.resourceObj.form.id
            await this.PostResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else await this.PutResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async Delete() {
      await this.DeleteResourcev2(this.resourceObj.resourceType, this.resourceObj.form.id, this.SuccessCallback)
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.fetchTable()
      this.CloseModal()
    },
  },
  async mounted() {
    await this.fetchTable()
    await this.getWork_centers()
  },
}
</script>
